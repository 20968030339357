import React, { Component } from "react"
class MyPayFrame extends Component {
  constructor(props) {
    super(props)
    this.launchChecker()
  }
  launchChecker() {
    const requestUrl = `${process.env.GATSBY_TRUSTA_API_URL}/check-payin-web?id=${this.props.tid}`
    setInterval(function(){
      fetch(requestUrl, {
        method: 'GET', 
        mode: 'cors', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.json();
      }).then((data) => {
        if (data.status === 'finish'){
          window.location = `https://t.me/${process.env.GATSBY_TELEGRAM_BOT}`
        }
      });
    // }.bind(this), 3000)
      }, 3000)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.launchChecker)
  }
  componentDidMount(){
    document.getElementById('payment-form').setAttribute("src", decodeURI(this.props.srcURL) );
    window.addEventListener('beforeunload', this.launchChecker)
  }

  render(){
    return(
    <iframe
      id="payment-form"
      src=""
      title="Token Generation Frame"
      sandbox="allow-scripts allow-forms allow-orientation-lock allow-top-navigation allow-same-origin"
      width="550px"
      height="700px"
      // style = {{"overflow":"hidden"}}
    />
    )
  }
}
export default MyPayFrame